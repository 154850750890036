.sliderNegative {
    :global(.MuiSlider-thumb[data-index="1"]) {
        display: none;
        width: 0;
        height: 0;
    }
}

.sliderPositive {
    :global(.MuiSlider-thumb[data-index="0"]) {
        display: none;
        width: 0;
        height: 0;
    }
}

.editInputError {
    :global(.MuiInput-input) {
        border-color: red;
    }
}
